$primary-color: #085133;
$yellow-color: #fec32d;
$black-color: #000000;
$light-pink: #FFF5CD;
$dark-gray: #555555;
$dark-lowgray: #BBBBBB;
$darklight-gray: #999999;
$light-gray: #dedede;
$light-gray-two: #d1d1d1;
$white-color: #ffffff;
$black-gray: #333333;
$warning-dark: #ffeea9;
$warning-light: #FFF5CD;
$green-btn: #2bc212;
$blue-light: #f9f9f9;
$border-light: #ebebeb;
$gray-color-new: #E9E9E9;
$new-border-color: #ced4da;
$mid-gray: #f4f4f4;

.yellow-color {
  color: $yellow-color;
}
.light-pink {
  color: $light-pink;
}
.dark-gray {
  color: $dark-gray;
}
.light-gray {
  color: $light-gray;
}
.light-gray-two {
  color: $light-gray-two;
}
.white-color {
  color: $white-color;
}
.warning-dark {
  color: $warning-dark;
}
.warning-light {
  color: $warning-light;
}
.green-btn {
  color: $green-btn !important;
}
.blue-light {
  color: $blue-light;
}
.darklight-gray {
  color: $darklight-gray;
}
.black-color {
  color: $black-color;
}
.primary-color {
  color: $primary-color;
}

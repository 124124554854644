@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?nc0qqg");
  src: url("../fonts/icomoon.eot?nc0qqg#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?nc0qqg") format("truetype"), url("../fonts/icomoon.woff?nc0qqg") format("woff"), url("../fonts/icomoon.svg?nc0qqg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-next:before {
  content: "\e903"; }

.icon-calendar:before {
  content: "\e904"; }

.icon-down-arrow-1:before {
  content: "\e905"; }

.icon-eddit:before {
  content: "\e906"; }

.icon-filter:before {
  content: "\e907"; }

.icon-accouts:before {
  content: "\e908"; }

.icon-agents:before {
  content: "\e909"; }

.icon-arrowbtn:before {
  content: "\e90a"; }

.icon-bank:before {
  content: "\e90b"; }

.icon-box:before {
  content: "\e90c"; }

.icon-cancel-order:before {
  content: "\e90d"; }

.icon-cancel:before {
  content: "\e90e"; }

.icon-checBox:before {
  content: "\e90f"; }

.icon-customer-group:before {
  content: "\e910"; }

.icon-customers:before {
  content: "\e911"; }

.icon-dashboard:before {
  content: "\e912"; }

.icon-delivery-agent:before {
  content: "\e913"; }

.icon-email:before {
  content: "\e914"; }

.icon-intransit:before {
  content: "\e915"; }

.icon-list:before {
  content: "\e916"; }

.icon-lock:before {
  content: "\e917"; }

.icon-logout:before {
  content: "\e918"; }

.icon-mail-1:before {
  content: "\e919"; }

.icon-notification:before {
  content: "\e91a"; }

.icon-order:before {
  content: "\e91b"; }

.icon-payoutSystemt:before {
  content: "\e91c"; }

.icon-phone:before {
  content: "\e91d"; }

.icon-pickuplocation:before {
  content: "\e91e"; }

.icon-pin:before {
  content: "\e91f"; }

.icon-plus:before {
  content: "\e920"; }

.icon-processing-centre:before {
  content: "\e921"; }

.icon-product:before {
  content: "\e922"; }

.icon-product2:before {
  content: "\e923"; }

.icon-reports:before {
  content: "\e924"; }

.icon-route:before {
  content: "\e925"; }

.icon-search:before {
  content: "\e926"; }

.icon-settings:before {
  content: "\e927"; }

.icon-shipment:before {
  content: "\e928"; }

.icon-signature:before {
  content: "\e929"; }

.icon-sort:before {
  content: "\e92a"; }

.icon-team:before {
  content: "\e92b"; }

.icon-tick:before {
  content: "\e92c"; }

.icon-truck:before {
  content: "\e92d"; }

.icon-up-and-down:before {
  content: "\e92e"; }

.icon-user:before {
  content: "\e92f"; }

.icon-verification:before {
  content: "\e930"; }

.icon-accept:before {
  content: "\e931"; }

.icon-law:before {
  content: "\e932"; }

.icon-ProcessingAgent:before {
  content: "\e933"; }

.icon-shapes-and-symbols:before {
  content: "\e934"; }

.icon-star:before {
  content: "\e935"; }

.icon-bin:before {
  content: "\e936"; }

.icon-bin2:before {
  content: "\e937"; }

.icon-eye:before {
  content: "\e938"; }

.icon-hide:before {
  content: "\e939"; }

.icon-password---Copy:before {
  content: "\e93a"; }

.icon-edit-ico:before {
  content: "\e93b"; }

.icon-unblockicon:before {
  content: "\e93c"; }

.icon-close1:before {
  content: "\e93d"; }

.icon-SwapBtn:before {
  content: "\e93e"; }

.icon-ManageAdmin1:before {
  content: "\e93f"; }

.icon-logout-new:before {
  content: "\e900"; }

.icon-notification-bell:before {
  content: "\e901"; }

.icon-setting-gear:before {
  content: "\e902"; }
